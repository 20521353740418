import React from 'react'
import CallToAction from './../components/CallToAction'
import MainText from './../components/MainText'
import { Link } from 'react-router-dom'
import { FaGithub, FaLinkedin } from "react-icons/fa";


const IntroductionPage = ({ isMobile }) => {
    return (
        <div className='IntroductionPage' dir='ltr'>
            {/* <img src={Asset4} alt="" className='backgroundimage'/> */}
            <div className='backgroundimage'></div>
            <div className="backgroundfilter"></div>
            <div></div>


            <div className='interface'>
                <div className='maintext'>
                    <MainText />
                </div>
                {isMobile ?
                    (
                        <div className='callToAction-mobile'>
                            <Link to='/contact' className='callToAction-mobile__button'><h2 >GET IN TOUCH</h2></Link>
                            <div className='callToAction-mobile__social'>
                                <a href="https://www.linkedin.com/in/ahmed-alghamdi0/" target='_blank' rel="noreferrer"><FaLinkedin size='30' /></a> &nbsp; &nbsp;
                                <a href="https://github.com/ASG07" target='_blank' rel="noreferrer"><FaGithub size='30' /></a>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className='callToAction'>
                            <CallToAction />
                        </div>
                    )
                }

            </div>


        </div>
    )
}

export default IntroductionPage

import React from 'react'
import { AiOutlineUser, AiOutlineMail, AiOutlineGithub, AiOutlineLinkedin } from 'react-icons/ai'
import { IoMdPaper, IoMdPhonePortrait } from 'react-icons/io'
// import { IoCodeSlashSharp } from 'react-icons/io5'
import { RiMailSendLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const NavSocial = () => {
    return (
        <>
            <div className='nav' >
                <Link to='/home'><div className='nav-intro'><span ><AiOutlineUser className='intro' size="35px" style={{ marginRight: 2.5, marginTop: 2 }} /></span></div></Link>
                <Link to='/resume'><div className='nav-carrer'><span className='carrer'><IoMdPaper size="35px" style={{ marginRight: 2.5, marginTop: 2 }} /></span></div></Link>
                {/* <Link to='/portfolio'><div className='nav-portfolio'><span className='portfolio'><IoCodeSlashSharp size="35px" style={{ marginRight: 2.5, marginTop: 2 }} /></span></div></Link> */}
                <Link to='/contact'><div className='nav-contact'><span className='contact'><RiMailSendLine size="35px" style={{ marginRight: 2.5, marginTop: 2 }} /></span></div></Link>
                {/* <div className='nav-theme'><span>as</span></div>
                    <div className='nav-language'><span>as</span></div> */}
            </div>
            <div className='social'>
                <a href='https://www.linkedin.com/in/ahmed-alghamdi0/' target='_blank' rel="noreferrer"><div className='social-linkedin'><span className='linkedin'><AiOutlineLinkedin size="35px" style={{ marginLeft: 2.5, marginTop: 2 }} /></span></div></a>
                <a href='https://github.com/ASG07' target='_blank' rel="noreferrer"><div className='social-github'><span className='github'><AiOutlineGithub size="35px" style={{ marginLeft: 2.5, marginTop: 2 }} /></span></div></a>
                {/* <Link><div className='social-satr'><span><RiMailSendLine size="35px" style={{marginLeft:3}}/></span></div></Link> */}
                <a href='tel:+966503888536'><div className='social-phone'><span className='phone'><IoMdPhonePortrait size="35px" style={{ marginLeft: 2.5, marginTop: 2 }} /></span></div></a>
                <a href='mailto:ahmedsamiredu@hotmail.com'><div className='social-email'><span className='email'><AiOutlineMail size="35px" style={{ marginLeft: 2.5, marginTop: 2 }} /></span></div></a>

            </div>
        </>
    )
}

export default NavSocial

import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const NavSocialMobile = ({ isMobile }) => {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen)
    }

    return (
        <div className={isNavOpen ? 'open' : ''}>
            <div id="top-head-inner">
                <div id="nav-toggle" onClick={toggleNav}>
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <nav id="global-nav">
                    <ul>
                        <li><Link to='/home' onClick={toggleNav}>HOME</Link></li>
                        <li><Link to='/resume' onClick={toggleNav}>RESUME</Link></li>
                        <li><Link to='/portfolio' onClick={toggleNav}>PROJECTS</Link></li>
                        <li><Link to='/contact' onClick={toggleNav}>CONTACT</Link></li>
                    </ul>
                    {/* <ul>
                        <li><Link to='/home' onClick={toggleNav}>الرئيسية</Link></li>
                        <li><Link to='/resume' onClick={toggleNav}>السيرة</Link></li>
                        <li><Link to='/portfolio' onClick={toggleNav}>المشاريع</Link></li>
                        <li><Link to='/contact' onClick={toggleNav}>للتواصل</Link></li>
                    </ul> */}
                </nav>
                <div id="nav-bg"></div>
            </div>
        </div>
    )
}

export default NavSocialMobile

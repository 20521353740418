import React, { useState, useCallback, useRef } from 'react';
import { MdEmail } from "react-icons/md";
import { FaPhoneSquare, FaCheckCircle } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import useForm from '../hooks/useForm';
import validate from '../validation/validateInfo';

const ContactPage = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const submitFormRef = useRef();

    const { handleChange, values, handleSubmit, errors } = useForm(() => submitFormRef.current(), validate);


    submitFormRef.current = useCallback(() => {
        setIsSubmitted(true);
        emailjs.send("service_1o7v7oi", "template_juvhrkn", {
            to_name: "Ahmed",
            message: values.message,
            sender_name: values.name,
            sender_email: values.email,
        }, "user_tvB2vn5NwZw4VbRQrp87x");
    }, [values]);

    return (

        <div className='contact-page' dir='ltr'>
            <div className='contact-title'>
                {/* <h1 className='contact-title__shadow'>GET IN TOUCH</h1> */}
                <h1 className='contact-title__title'>GET IN TOUCH</h1>
            </div>

            <div className='contact-container'>
                {!isSubmitted ? (
                    <form className='contact-form' onSubmit={handleSubmit} noValidate>
                        <input id='name' name='name' type="text" placeholder='NAME*' onChange={handleChange} value={values.name} />
                        {errors.name && <p className='error'>{errors.name}</p>}
                        <input id='email' name='email' type="email" placeholder='EMAIL*' onChange={handleChange} value={values.email} />
                        {errors.email && <p className='error'>{errors.email}</p>}
                        <textarea id='message' style={{ resize: 'none' }} name="message" placeholder='MESSAGE*' cols="100" rows="10" onChange={handleChange} value={values.message}></textarea>
                        {errors.message && <p className='error'>{errors.message}</p>}
                        <div><input type='submit' value='SUBMIT'></input></div>
                    </form>
                ) : (
                    <h1 className='form-success'>The message was sent, Thank you for your time &nbsp;<FaCheckCircle /></h1>
                )}
                <div className='contact-container__email' dir='ltr'><MdEmail size='30' />&nbsp;&nbsp;&nbsp;<a href='mailto:ahmedsamiredu@hotmail.com' style={{ color: "whitesmoke" }}><h4>ahmedsamiredu@hotmail.com</h4></a></div>
                <div className='contact-container__phone' dir='ltr'><FaPhoneSquare size='25' /> &nbsp;&nbsp;&nbsp;<a href="tel:0503888536" style={{ color: "whitesmoke" }}><h4>050 388 8536</h4></a></div>
            </div>
        </div>


    );
}

export default ContactPage;

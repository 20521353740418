import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PortfolioPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/home');
    }, [navigate])

    return (
        <div className='portfolio-page' dir='ltr'>
            <div className='portfolio-title'>
                <h1 className='portfolio-title__shadow'>PROJECTS</h1>
                <h1 className='portfolio-title__title'>PROJECTS</h1>
            </div>
            <div className="portfolio-container">
                <div className="card">

                    <div className="card__info">
                        <div className="card__info__title" >
                            <h1>The Weather</h1>
                        </div>
                        <div className="card__info__body" >
                            <h3>The Weather webapp shows the weather in your city if the GPS is enabled or you can use the search field</h3>
                            {/* <h3>موقع يعرض الاحوال الجويه عن طريق موقع المستخدم او خانة البحث, ومتجاوب مع الاجهزة المحمولة</h3> */}
                        </div>
                        <div className="card__info__footer">
                            <div className="card__info__footer__button">
                                <a className='card__info__footer__button__redirect-button' href="https://quizzical-lamport-0b.netlify.app/" target='_blank' rel="noreferrer">TRY IT</a>
                            </div>
                            <div className="card__info__footer__tech">
                                <i className='devicon-javascript-plain colored'></i>
                                <i className='devicon-react-plain colored'></i>
                                <i className='devicon-css3-plain colored'></i>

                            </div>

                        </div>
                    </div>
                    <div className="card__image"><div className="card__image__weather"></div></div>
                </div>
                <div className="card">

                    <div className="card__info">
                        <div className="card__info__title" >
                            <h1>Portfilio</h1>
                        </div>
                        <div className="card__info__body" >
                            <h3>a portfolio that was built with passion</h3>
                        </div>
                        <div className="card__info__footer">
                            <div className="card__info__footer__button">
                                <a className='card__info__footer__button__redirect-button' href="https://ahmed-sameer.com/" target='_blank' rel="noreferrer">TRY IT</a>
                            </div>
                            <div className="card__info__footer__tech">
                                <i className='devicon-javascript-plain colored'></i>
                                <i className='devicon-react-plain colored'></i>
                                <i className='devicon-sass-plain colored'></i>

                            </div>

                        </div>
                    </div>
                    <div className="card__image"><div className="card__image__portfolio"></div></div>
                </div>
                <div className="card">

                    <div className="card__info">
                        <div className="card__info__title" >
                            <h1>Coming Soon...</h1>
                        </div>
                        <div className="card__info__body" >

                        </div>
                        <div className="card__info__footer">
                            <div className="card__info__footer__button">

                            </div>
                            <div className="card__info__footer__tech">


                            </div>

                        </div>
                    </div>
                    <div className="card__image"><div className="card__image__coming-soon"></div></div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioPage

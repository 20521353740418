import React from 'react'

const Button = ({ value, icon }) => {
    return (
        <div >

            <div className='button' dir='ltr'><span className='icon'>{icon}</span>&nbsp;&nbsp;{value}</div>
        </div>
    )
}

export default Button


import './Style/style.scss';
import IntroductionPage from './pages/IntroductionPage';
import ResumePage from './pages/ResumePage';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import { Route, Routes } from 'react-router-dom'
import NavSocial from './components/NavSocial'
import WaveDivider from './components/WaveDivider'
import { useState, useEffect } from 'react'
import NavSocialMobile from './components/NavSocialMobile';

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [viewWidth, setViewWidth] = useState(window.innerWidth);

  const checkWidth = () => {
    setViewWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', checkWidth)
    //console.log(window.innerWidth)
    if (viewWidth < 800 && isMobile === false) {
      //console.log('Mobile')
      setIsMobile(true);
    }
    else if (viewWidth > 800 && isMobile) {
      //console.log('Desktop')
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener('resize', checkWidth)
    }
  }, [viewWidth, isMobile])



  return (
    <div className="App">

      {isMobile ?
        <div className='nav-social-mobile'><NavSocialMobile isMobile={isMobile} /></div>
        :
        (<div className='nav-social'>
          <NavSocial />
        </div>)}


      <Routes>
        <Route path='/' exact element={<IntroductionPage isMobile={isMobile} />} ></Route>
        <Route path='/home' element={<IntroductionPage isMobile={isMobile} />}></Route>
        <Route path='/resume' element={<ResumePage />}></Route>
        <Route path='/portfolio' element={<PortfolioPage />}></Route>
        <Route path='/contact' element={<ContactPage />}></Route>
      </Routes>


      <div className='waveDivider'>

      </div><WaveDivider />
    </div>
  );
}

export default App;

const validateInfo = (values) =>{
    let errors = {};

    if(!values.name.trim()){
        errors.name = "حقل الاسم فارغ"
    }

    //email
    if(!values.email.trim()){
        errors.email = "حقل البريد الالكتروني فارغ"
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
        errors.email = "البريد الالكتروني غير صحيح"
    }

    if(!values.message.trim()){
        errors.message = "حقل الرسالة فارغ"
    }

    return errors;
}
export default validateInfo
import React from 'react'
import Asset1 from '../assets/Asset 2.svg'
import Asset2 from '../assets/Asset 3.svg'
// import Asset3 from '../assets/Asset 4.svg'
// import Asset4 from '../assets/Asset 5.svg'
import { Link } from 'react-router-dom'

const CallToAction = () => {
    return (
        <div className='logo-container'>
            <img src={Asset1} className="App-logo filter_orange" alt="logo" />
            <img src={Asset2} className="App-logo2 filter_orange" alt="logo" />
            <Link to='/contact' className="call-to-action">
                {/* <h3>
                    تواصل
                </h3> */}
                <h3>
                    Get In <br /> Touch
                </h3>
            </Link>
            {/* <img src={Asset3} className="App-logo3" alt="logo" />
          <img src={Asset4} className="App-logo4" alt="logo" /> */}
        </div>

    )
}

export default CallToAction
